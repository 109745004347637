import { Routes } from '@angular/router';
import { servicesResolver } from './features/shared/services.resolver';
import { appointmentResolver } from './features/shared/appointment.resolver';
import { environment } from 'src/environments/environment';
import { NotFoundComponent } from './features/shared/not-found/not-found.component';

const defaultLocationUuid = environment.defaultLocationUuid;
export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: `/${defaultLocationUuid}/services` },
  {
    path: ':locationUuid/services',
    resolve: { servicesData: servicesResolver, appointmentData: appointmentResolver },
    loadChildren: () => import('./features/appointment/appointment.routes').then((m) => m.APPOINTMENT_ROUTES),
  },
  {
    path: ':locationUuid/toolkit',
    loadChildren: () => import('./features/toolkit/toolkit.routes').then((m) => m.TOOLKIT_ROUTES),
  },
  { path: 'page-not-found', data: { showHeader: true, hideSider: true }, component: NotFoundComponent },
  { path: '**', data: { showHeader: true, hideSider: true }, component: NotFoundComponent },
];
