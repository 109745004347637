import { AfterViewInit, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { LayoutComponent } from './features/layout/layout.component';
import { SpinService } from '@services/spin.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { SubscribedComponent } from './lib/subscribed.component';
import { PreloaderService } from '@services/preloader.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, NzSpinModule, NzLayoutModule, LayoutComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent extends SubscribedComponent implements OnInit, AfterViewInit {
  title = 'widget';
  loading$ = this.spinService.getCurrentGlobalSpinStore();

  constructor(
    private router: Router,
    private ref: ChangeDetectorRef,
    private spinService: SpinService,
    private preloader: PreloaderService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event: NzSafeAny) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.spinService.setCurrentGlobalSpinStore(false);
        this.ref.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    this.preloader.removePreLoader();
    this.ref.detectChanges();
  }
}
